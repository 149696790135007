import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { singleMember, memberSpaces, memberInvoice, memberActive } from '../../api/member';
import { DESKIE_API as API } from '../../config';
import memberBlank from "../../Assets/Images/icon/memberAvatar.svg";
import spacesBlank from "../../Assets/Images/icon/spaceAvatar.png";
import more from "../../Assets/Images/icon/dots-vertical.svg";
import { Link, useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';
import penIcon from "../../Assets/Images/icon/pencil-01.svg";
import alert from "../../Assets/Images/icon/alert.png";
import EditMember from './EditMember';
import memberAvatar from "../../Assets/Images/icon/memberLargeIcon.png";
import spacesIcon from "../../Assets/Images/icon/spaceAvatar.png";
import PhoneInput from 'react-phone-input-2';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { ToastContainer } from 'react-toastify';
import { singleProfile } from '../../api/settings';
import { reminderMember } from './../../api/member';
import { getAgreement } from '../../api/agreement';

const ViewMember = () => {
    const { id } = useParams();
    const [memberDetails, setMemberDetails] = useState<any>({});
    const [spacesList, setSpacesList] = useState<any>([]);
    const [invoiceList, setInvoiceList] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [memberId, setMemberId] = useState("");
    const [updateShow, setUpdateShow] = useState(false);
    const handleUpdateClose = () => setUpdateShow(false);
    const [companyName, setCompanyName] = useState("");
    const [darkLogoImage, setDarkLogoImage] = useState("");
    const [address, setAddress] = useState("");
    const [emailContent, setEmailContent] = useState('')

    useEffect(() => {
        if (id) {
            singleMember(id).then((data) => {
                setMemberDetails(data.data && data.data);
            }).catch((err) => {console.log(err)});
            
            memberSpaces(id).then((data) => {
                setSpacesList(data.spaces && data.spaces);
            }).catch((err) => {console.log(err)})

            memberInvoice(id).then((data) => {
                setInvoiceList(data.invoice && data.invoice);
            }).catch((err) => {console.log(err)});
        }
        getAgreement().then((data) => {
            if (data.data.welcome_email) {
                setEmailContent(data.data.welcome_email)   
            }
          }).catch((err) => { console.log(err) });

    }, [count]);
    const getSingleProfile = async () => {
        try {
            const {data} = await singleProfile();
            setCompanyName(data.company_name);
            setDarkLogoImage(data.company_logo_dark);
            setAddress(data.address);
        } catch (err) {
            console.log(err);
        };
    };

    useEffect(() => {
        getSingleProfile();
    }, []);

    // member update view
    const memberUpdate = (memberId: string) => {
        setMemberId(memberId);
        setUpdateShow(true);
    }


    const disableMember = (memberId: string, active: boolean) => {
        let memberInfo = {
            "memberId": memberId,
            "active": active
        }
        memberActive(memberInfo).then(() => {
            showNotifications(TOAST_TYPE.success, "Status updated successfully")
            setCount(count + 1)
        }).catch((err) => {console.log(err)});
    }


    const reminderEmail = () => {
        let memberInfo = {
          firstName: memberDetails.first_name,
          lastName: memberDetails.last_name,
          email: memberDetails.email,
          businessName: memberDetails.business_name,
          businessEmail: memberDetails.business_email,
          notes: memberDetails.notes,
          id: memberDetails.id,
          phoneNumber: memberDetails.phone_number,
          businessPhone: memberDetails.business_phone,
          companyName: companyName,
          darkLogo: darkLogoImage,
          address: address,
          logoImage: `${API}/${encodeURI(darkLogoImage)}`,
          active: 'true',
          emailContent: emailContent
        }
        reminderMember(memberInfo).then(() => {
            showNotifications(TOAST_TYPE.success, "Onboarding reminder sent")
            setCount(count + 1)
        }).catch((err) => {console.log(err)});

      }
    

    return (
        <div id='view-member'>
            <Layout>
                <ToastContainer />
                <div className='mainContent'>
                    <div className="invoiceHeading">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb m-0 ms-2">
                                <li className="breadcrumb-item"><Link to="/member">Members</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{memberDetails.first_name} {memberDetails.last_name}</li>
                            </ol>
                        </nav>
                    </div>

                    {memberDetails.signature_image && memberDetails.signature  ? "" : <div className="incomplete-card">
                        <div className="icon-cnt">
                            <img src={alert} alt="alert" />
                        </div>
                        <div className="content-cnt">
                            <p><b>Onboarding Incomplete</b></p>
                            <p>This member hasn’t finished their account setup.</p>
                            <p className='reminder' onClick={reminderEmail}><b>Send Reminder</b></p>
                        </div>
                    </div>}

                    <div className="membersDetails">
                        <div className="topLine">
                            <div className='tableHeading'>
                                <div className="memberName">
                                    <Link to="/member" className='backDashboard'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                                    {memberDetails.member_image ?
                                        <><img src={`${API}/${memberDetails.member_image}`} alt="avatar" style={{ borderRadius: "50%", objectFit: "cover" }} /></>
                                        : <><img src={memberAvatar} alt="avatar" style={{ borderRadius: "50%" }} /></>
                                    }
                                    <div>
                                        <h6 className='mb-1'>{memberDetails.first_name} {memberDetails.last_name}</h6>
                                        <p>Member</p>
                                    </div>
                                </div>
                            </div>
                            <div className="editBtn">
                                 <span className={'invite status '+ (memberDetails.active ? 'active':'inactive')}>{memberDetails.active ? 'Active':'Inactive'}</span>
                                {memberDetails.active ? <button className='disableMember' onClick={() => disableMember(memberDetails.id, memberDetails.active)}>Disable Member</button>
                                    : <button className='activeMember' onClick={() => disableMember(memberDetails.id, memberDetails.active)}>Active Member</button>}
                                <button className='edit py-2' onClick={() => memberUpdate(memberDetails.id)}><img src={penIcon} alt="edit" /> Edit Member</button>
                            </div>
                        </div>
                        <div className="memberInfo">
                            <div className="memberInfoBox">
                                <h6>Phone Number</h6>
                                <PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={memberDetails.phone_number} />
                            </div>
                            <div className="memberInfoBox" style={{ borderLeft: '1px solid  rgba(234, 236, 240, 1)', borderRight: '1px solid  rgba(234, 236, 240, 1)' }}>
                                <h6>Email Address</h6>
                                <p>{memberDetails.email}</p>
                            </div>
                            <div className="memberInfoBox">
                                <h6>Account Signup Date</h6>
                                <p>{moment(memberDetails.created_at).format("MMMM DD, YYYY")}</p>
                            </div>
                            <div className="memberInfoBox">
                                <h6>Business Name</h6>
                                <p>{memberDetails.business_name}</p>
                            </div>
                            <div className="memberInfoBox" style={{ borderLeft: '1px solid  rgba(234, 236, 240, 1)', borderRight: '1px solid  rgba(234, 236, 240, 1)' }}>
                                <h6>Business Phone</h6>
                                <PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={memberDetails.business_phone} />
                            </div>
                            <div className="memberInfoBox">
                                <h6>Business Email</h6>
                                <p>{memberDetails.business_email}</p>
                            </div>
                        </div>
                        <div className="memberInvoice">
                            <div className="invoiceLeft">
                                <div className="memberNotes">
                                    <h6>Notes</h6>
                                    <p className='mb-0'>{memberDetails.notes ? memberDetails.notes : "You haven’t added any notes for this user."}</p>
                                </div>
                                <div className="memberAssign mt-4">
                                    <h6 className='mb-3'>{memberDetails.first_name}’s Active Assignments</h6>
                                    {spacesList && spacesList.length ?
                                        spacesList && spacesList.map((spaces: any) =>
                                            <div className="invoiceHeading">
                                                <div className="invoiceName">
                                                    <p>{spaces.spaces_image ? <img src={`${API}/${spaces.spaces_image}`} alt="member" /> : <img src={spacesIcon} alt="spaces" />} <span>{spaces.spaces_name}</span> </p>
                                                    <div className='deskType'>
                                                        {spaces.spaces_tag === "private" ? <span className='private'>Private Office</span> : ""}
                                                        {spaces.spaces_tag === "dedicated" ? <span className='dedicated'>Dedicated Desk</span> : ""}
                                                        {spaces.spaces_tag === "flex" ? <span className='flex'>Flex</span> : ""}
                                                    </div>
                                                </div>

                                                <div className="invoicePrice billingAction">
                                                    <p>${spaces.amount} <span>/mo</span> </p>
                                                    <button className='btn download'><img src={more} alt="download" /></button>
                                                </div>
                                            </div>
                                        ) : <p className='mb-0'>No active assignments.</p>
                                        }
                                </div>
                                <div className="invoiceHistory mt-4">
                                    <div className='mb-3 d-flex justify-content-between'>
                                        <h6 className='mb-0'>{memberDetails.first_name}’s Invoice History</h6>
                                    </div>
                                    {invoiceList && invoiceList.length ?
                                        <div className="invoiceMemberList">
                                            {invoiceList && invoiceList.map((invoice: any) =>
                                                <div className="invoiceBox">
                                                    <div className="invoiceHeading">
                                                        <div className="invoiceName">
                                                            <h6>{invoice.spaces_name ? invoice.spaces_name : "N/A"}</h6>
                                                        </div>
                                                        <div className="invoicePrice billingAction">
                                                            <p>${invoice.amount}</p>
                                                            <button className='btn download'><img src={more} alt="download" /></button>
                                                        </div>
                                                    </div>
                                                    <div className="invoiceDetails">
                                                        {/* <div className="assign">
                                                            <h6>Assignment</h6>
                                                            <p> {invoice.spaces_image ? <img src={`${API}/${invoice.spaces_image}`} alt="member" /> : <img src={spacesBlank} alt="member" />} <span>{invoice.spaces_name ? invoice.spaces_name : "N/A"}</span> </p>
                                                        </div> */}
                                                        <div className="assign">
                                                            <h6>Due Date</h6>
                                                            <p>{invoice.invoice_date ? <>{moment(invoice.invoice_date).format("MMMM DD, YYYY")}</> : "N/A"}</p>
                                                        </div>
                                                        <div className="assign">
                                                            <h6>Status</h6>
                                                            <p className='status'>
                                                                {invoice.invoice_status === "paid" ? <span className='paid'>Paid</span>
                                                                    : invoice.invoice_status === "void" ? <span className='unpaid'>Void</span>
                                                                        : <span className='unpaid'>Unpaid</span>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div> : <p className='mb-0'>No invoice history.</p>
                                    }
                                </div>
                            </div>
                            {/* <div className="invoiceRight">
                                <div className="memberBooking">
                                    <h6 className='mb-3'>{memberDetails.first_name}’s Upcoming Bookings</h6>
                                    <p className='mb-0'>No upcoming bookings.</p>
                                    <div className="bookingList">
                                        <img src={`${API}/${memberDetails.member_image}`} alt="member" />
                                        <div className="spacesInfo">
                                            <h6>{memberDetails.member_image}</h6>
                                            <p>asa</p>
                                        </div>
                                    </div>
                                    <div className="bookingList">
                                        <img src={`${API}/${memberDetails.member_image}`} alt="member" />
                                        <div className="spacesInfo">
                                            <h6>{memberDetails.member_image}</h6>
                                            <p>asa</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
                <EditMember memberId={memberId} updateShow={updateShow} setUpdateShow={setUpdateShow} handleUpdateClose={handleUpdateClose} />
            </Layout>
        </div>
    )
}

export default ViewMember