import React, { useEffect, useState } from "react";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bell from "../../Assets/Images/icon/bell-01.svg";
import circle from "../../Assets/Images/icon/info-circle.png";
import arrow from "../../Assets/Images/icon/downIcon.svg";
import userIcon from "../../Assets/Images/icon/assign.svg";
import invoiceDetail from "../../Assets/Images/icon/invoice-detail.svg";
import logout from "../../Assets/Images/icon/logout.png";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { DESKIE_API as API } from "../../config";
import { Link } from "react-router-dom";
import memberIcon from "../../Assets/Images/icon/memberAvatar.svg";
import { notificationsList } from "../../api/notification";
import logoutUser from "../../utils/logoutUser";
import { singleJwtMember } from "../../api/member";

const Header = ({ onValueChange }: any) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [userImage, setUserImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [notifyList, setNotifyList] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleClick = () => {
    setCollapsed(!collapsed);
    onValueChange(collapsed);
  };
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const urlParams = pathArray[pathArray.length - 1];

  useEffect(() => {
    singleJwtMember()
      .then((res) => {
        const { data } = res.data;
        if (data.member_image) {
          setUserImage(data.member_image);
        } else {
          setUserImage(data.avatar);
        }
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setUserRole(data.role);
      })
      .catch((err) => {
        console.log(err);
      });

    notificationsList()
      .then((data) => {
        if (Array.isArray(data)) {
          setNotifyList(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredNotify = notifyList.filter((notify: any) => {
    // Invoice id data type is updated from string to number
    notify.invoice_id
      .toString()
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  });

  const handleLogout = () => {
    navigate("/");
    logoutUser();
  };

  const renderPageTitle = () => {
    if (pathArray.includes("member")) {
      return <p>Members</p>;
    } else if (pathArray.includes("assets")) {
      return <p>Assets</p>;
    } else if (pathArray.includes("accounts")) {
      return <p>Accounts</p>;
    } else if (pathArray.includes("calender")) {
      return <p>Calendar</p>;
    } else if (pathArray.includes("billing")) {
      return <p>Billing</p>;
    } else if (pathArray.includes("visitor-log")) {
      return <p>Visitors</p>;
    } else if (pathArray.includes("tours")) {
      return <p>Tours</p>;
    } else if (pathArray.includes("invoice-details")) {
      return <p>Billing</p>;
    } else if (urlParams === "my-invoice") {
      return <p>Billing</p>;
    } else if (urlParams === "files") {
      return <p>Files</p>;
    } else if (urlParams === "tickets") {
      return <p>Ticket</p>;
    } else if (urlParams === "task") {
      return <p>Tasks</p>;
    } else if (urlParams === "dashboard") {
      return <p>Dashboard</p>;
    } else if (urlParams === "messenger") {
      return <p>Messages</p>;
    } else if (urlParams === "feed") {
      return <p>Feed</p>;
    } else if (urlParams === "settings") {
      return <p>Settings</p>;
    } else if (urlParams === "my-home") {
      return <p>Home</p>;
    } else if (pathArray.includes("resources")) {
      return <p>Resources</p>;
    } else if (pathArray.includes("my-resources")) {
      return <p>My Bookings</p>;
    } else if (pathArray.includes("assignments")) {
      return <p>Assignments</p>;
    } else if (pathArray.includes("my-feed")) {
      return <p>Feed</p>;
    } else if (pathArray.includes("my-messenger")) {
      return <p>Messenger</p>;
    } else if (pathArray.includes("my-files")) {
      return <p>Files</p>;
    } else if (pathArray.includes("my-settings")) {
      return <p>Settings</p>;
    } else if (pathArray.includes("my-door") || pathArray.includes("door")) {
      return <p>Door Access</p>;
    }


    return null; // Return null if no conditions are met
  };

  return (
    <>
      <div className='topNavbar'>
        <div className='contentHeading'>
          <div className="d-none d-lg-flex align-items-center gap-3">
            <button className='sb-button pcIcon' onClick={handleClick}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div className="d-none d-md-block">
              {renderPageTitle()}
            </div>
          </div>
            <div className="mobileDropdown">
              <Dropdown className="dropVanIcon">
                <Dropdown.Toggle>
                  <button className='sb-button px-0' onClick={handleClick}>
                    <FontAwesomeIcon icon={faBars} />
                  </button>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className='mobileNav'>
                    <ul className='list-unstyled'>
                      <li
                        className={
                          urlParams === "my-home" ? "navLink activeMenu" : "navLink"
                        }
                      >
                        <Link to='/my-home'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M21 9L14.5515 13.6061C14.3555 13.746 14.2576 13.816 14.1527 13.8371C14.0602 13.8557 13.9643 13.8478 13.8762 13.8142C13.7762 13.7762 13.691 13.691 13.5208 13.5208L10.4792 10.4792C10.309 10.309 10.2238 10.2238 10.1238 10.1858C10.0357 10.1522 9.9398 10.1443 9.84732 10.1629C9.74241 10.184 9.64445 10.254 9.44853 10.3939L3 15M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z'
                              stroke={
                                urlParams === "my-home" ? "#FFFFFF" : "#98A2B3"
                              }
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                          Home
                        </Link>
                      </li>
                      <li
                        className={
                          urlParams === "my-resources"
                            ? "navLink activeMenu"
                            : "navLink"
                        }
                      >
                        <Link to='/my-resources'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <g clipPath='url(#clip0_10553_24214)'>
                              <path
                                d='M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM13 14H15V11H18V9H15V6H13V9H10V11H13V14Z'
                                fill={
                                  urlParams === "my-resources"
                                    ? "#FFFFFF"
                                    : "#98A2B3"
                                }
                              />
                            </g>
                            <defs>
                              <clipPath id='clip0_10553_24214'>
                                <rect width='24' height='24' fill='white' />
                              </clipPath>
                            </defs>
                          </svg>
                          Resources
                        </Link>
                      </li>
                      <li
                        className={
                          urlParams === "my-feed" ? "navLink activeMenu" : "navLink"
                        }
                      >
                        <Link to='/my-feed'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M8 8V7M8 12.5V11.5M8 17V16M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z'
                              stroke={
                                urlParams === "my-feed" ? "#FFFFFF" : "#98A2B3"
                              }
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                          Feed
                        </Link>
                      </li>
                      <li
                        className={
                          urlParams === "my-messenger"
                            ? "navLink activeMenu"
                            : "navLink"
                        }
                      >
                        <Link to='/my-messenger'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M7.5 12H7.51M12 12H12.01M16.5 12H16.51M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.1971 3.23374 14.3397 3.65806 15.3845C3.73927 15.5845 3.77988 15.6845 3.798 15.7653C3.81572 15.8443 3.8222 15.9028 3.82221 15.9839C3.82222 16.0667 3.80718 16.1569 3.77711 16.3374L3.18413 19.8952C3.12203 20.2678 3.09098 20.4541 3.14876 20.5888C3.19933 20.7067 3.29328 20.8007 3.41118 20.8512C3.54589 20.909 3.73218 20.878 4.10476 20.8159L7.66265 20.2229C7.84309 20.1928 7.9333 20.1778 8.01613 20.1778C8.09715 20.1778 8.15566 20.1843 8.23472 20.202C8.31554 20.2201 8.41552 20.2607 8.61549 20.3419C9.6603 20.7663 10.8029 21 12 21ZM8 12C8 12.2761 7.77614 12.5 7.5 12.5C7.22386 12.5 7 12.2761 7 12C7 11.7239 7.22386 11.5 7.5 11.5C7.77614 11.5 8 11.7239 8 12ZM12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12ZM17 12C17 12.2761 16.7761 12.5 16.5 12.5C16.2239 12.5 16 12.2761 16 12C16 11.7239 16.2239 11.5 16.5 11.5C16.7761 11.5 17 11.7239 17 12Z'
                              stroke={
                                urlParams === "my-messenger" ? "#FFFFFF" : "#98A2B3"
                              }
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                          My Message
                        </Link>
                      </li>
                      <li
                        className={
                          urlParams === "my-files"
                            ? "navLink activeMenu"
                            : "navLink"
                        }
                      >
                        <Link to='/my-files'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7Z'
                              stroke={
                                urlParams === "my-files" ? "#FFFFFF" : "#98A2B3"
                              }
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                          My Files
                        </Link>
                      </li>
                      <li
                        className={
                          urlParams === "my-invoice"
                            ? "navLink activeMenu"
                            : "navLink"
                        }
                      >
                        <Link to='/my-invoice'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M16 8.00007V4.50073C16 3.66899 16 3.25312 15.8248 2.99755C15.6717 2.77425 15.4346 2.62257 15.1678 2.57715C14.8623 2.52517 14.4847 2.69944 13.7295 3.04799L4.85901 7.14206C4.18551 7.45291 3.84875 7.60834 3.60211 7.84939C3.38406 8.06249 3.21762 8.32262 3.1155 8.6099C3 8.93486 3 9.30575 3 10.0475V15.0001M16.5 14.5001H16.51M3 11.2001L3 17.8001C3 18.9202 3 19.4802 3.21799 19.9081C3.40973 20.2844 3.71569 20.5903 4.09202 20.7821C4.51984 21.0001 5.07989 21.0001 6.2 21.0001H17.8C18.9201 21.0001 19.4802 21.0001 19.908 20.7821C20.2843 20.5903 20.5903 20.2844 20.782 19.9081C21 19.4802 21 18.9202 21 17.8001V11.2001C21 10.08 21 9.51992 20.782 9.09209C20.5903 8.71577 20.2843 8.40981 19.908 8.21806C19.4802 8.00007 18.9201 8.00007 17.8 8.00007L6.2 8.00007C5.0799 8.00007 4.51984 8.00007 4.09202 8.21806C3.7157 8.40981 3.40973 8.71577 3.21799 9.09209C3 9.51991 3 10.08 3 11.2001ZM17 14.5001C17 14.7762 16.7761 15.0001 16.5 15.0001C16.2239 15.0001 16 14.7762 16 14.5001C16 14.2239 16.2239 14.0001 16.5 14.0001C16.7761 14.0001 17 14.2239 17 14.5001Z'
                              stroke={
                                urlParams === "my-invoice" ? "#FFFFFF" : "#98A2B3"
                              }
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                          My Billing
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex d-lg-none align-items-center gap-3">
            <button className='sb-button pcIcon' onClick={handleClick}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div className="d-lg-none">
              {renderPageTitle()}
            </div>
          </div>
          <div className='rightNavbar'>
            {/* <button className='d-flex align-items-center'><img src={circle} alt="circle" /></button> */}
            <div className='notificationBox'>
              <Dropdown>
                <Dropdown.Toggle className="notification-icon">
                  <img src={bell} alt='bell' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className='notifyHeading'>
                    <p>Notifications</p>
                    <img src={bell} alt='bell' />
                  </div>
                  {filteredNotify && filteredNotify.length ? (
                    <>
                      <div className='searchInput mt-3'>
                        <input
                          type='text'
                          placeholder='Search notification'
                          onChange={handleInputChange}
                          className='form-control'
                        />
                        <FontAwesomeIcon icon={faSearch} />
                      </div>
                      <div className='latestHeading'>
                        <h5>LATEST</h5>
                      </div>
                      <div className='latestNotify'>
                        {filteredNotify &&
                          filteredNotify.map((notify: any, i) => (
                            <Link
                              key={`notify` + i}
                              className='notifyBox'
                              to={`/${userRole === "admin"
                                ? "invoice-details"
                                : "my-invoice-details"
                                }/${notify.id}`}
                            >
                              <h6>You have a new invoice</h6>
                              <h5>
                                Invoice : <span>#{notify.invoice_id}</span>
                              </h5>
                              <p>{notify.running_time} ago</p>
                            </Link>
                          ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='latestNotify text-center mt-5'>
                        <h6>No new notifications</h6>
                      </div>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Dropdown>
              <Dropdown.Toggle className="mainMenuButton">
                <button className='memberImg' >
                  {userImage && userImage.length ? (
                    <img
                      src={`${API}/${userImage}`}
                      style={{ objectFit: "cover" }}
                      alt='logo'
                    />
                  ) : (
                    <img
                      className='default'
                      src={memberIcon}
                      alt='bell'
                      style={{ objectFit: "cover" }}
                    />
                  )}
                </button>
              </Dropdown.Toggle>
              <Dropdown.Toggle className="headerName-btn">
                <div className="headerUserName">
                  {firstName && firstName} {lastName && lastName}{" "}
                  <img style={{ marginLeft: "12px" }} src={arrow} alt='arrow' />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className='navProfile'>
                  {userImage && userImage.length ? (
                    <img
                      className='logo'
                      src={`${API}/${userImage}`}
                      style={{ objectFit: "cover" }}
                      alt='logo'
                    />
                  ) : (
                    <img
                      className='logo default'
                      src={memberIcon}
                      alt='bell'
                      style={{ objectFit: "cover" }}
                    />
                  )}
                  <h6>
                    {firstName && firstName} {lastName && lastName}
                  </h6>
                  <p>{userRole && userRole === "admin" ? "Admin" : "Member"}</p>
                  {userRole === "admin" ? (
                    <Link to='/settings'>
                      <img src={userIcon} alt='admin' /> Profile
                    </Link>
                  ) : (
                    <Link to='/my-settings'>
                      <img src={userIcon} alt='admin' /> Profile
                    </Link>
                  )}
                  <div className='logout' onClick={handleLogout}>
                    <img src={logout} alt='logout' />
                    <span>Logout</span>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* mobile responsive */}
      <nav className="mobile-bottom-nav">
        <div className="footerNavbar">
          <Link to="/my-home">
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M21 9L14.5515 13.6061C14.3555 13.746 14.2576 13.816 14.1527 13.8371C14.0602 13.8557 13.9643 13.8478 13.8762 13.8142C13.7762 13.7762 13.691 13.691 13.5208 13.5208L10.4792 10.4792C10.309 10.309 10.2238 10.2238 10.1238 10.1858C10.0357 10.1522 9.9398 10.1443 9.84732 10.1629C9.74241 10.184 9.64445 10.254 9.44853 10.3939L3 15M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z'
                stroke={
                  urlParams === "my-home" ? "#FFFFFF" : "#98A2B3"
                }
                strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <span>Home</span>
          </Link>
        </div>
        <div className="footerNavbar">
          <Link to="/my-resources">
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clipPath='url(#clip0_10553_24214)'>
                <path
                  d='M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM13 14H15V11H18V9H15V6H13V9H10V11H13V14Z'
                  fill={
                    urlParams === "my-resources"
                      ? "#FFFFFF"
                      : "#98A2B3"
                  }
                />
              </g>
              <defs>
                <clipPath id='clip0_10553_24214'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <span>Resources</span>
          </Link>
        </div>
        <div className="footerNavbar lockBtn">
          <Link to="/my-door">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_14846_20309)">
                <path d="M21.1384 18.3239H15.6291V14.651H13.1682C12.1215 16.8731 9.86264 18.3239 7.36507 18.3239C3.82072 18.3239 0.9375 15.4407 0.9375 11.8963C0.9375 8.35197 3.82072 5.46875 7.36507 5.46875C9.86264 5.46875 12.1123 6.91954 13.1682 9.14165H22.9749V14.651H21.1384V18.3239ZM17.4655 16.4874H19.302V12.8145H21.1384V10.9781H11.9011L11.6899 10.3629C11.0471 8.53562 9.30252 7.3052 7.36507 7.3052C4.83077 7.3052 2.77395 9.36202 2.77395 11.8963C2.77395 14.4306 4.83077 16.4874 7.36507 16.4874C9.30252 16.4874 11.0471 15.257 11.6899 13.4298L11.9011 12.8145H17.4655V16.4874ZM7.36507 14.651C5.85 14.651 4.6104 13.4114 4.6104 11.8963C4.6104 10.3812 5.85 9.14165 7.36507 9.14165C8.88014 9.14165 10.1197 10.3812 10.1197 11.8963C10.1197 13.4114 8.88014 14.651 7.36507 14.651ZM7.36507 10.9781C6.86005 10.9781 6.44685 11.3913 6.44685 11.8963C6.44685 12.4013 6.86005 12.8145 7.36507 12.8145C7.87009 12.8145 8.28329 12.4013 8.28329 11.8963C8.28329 11.3913 7.87009 10.9781 7.36507 10.9781Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_14846_20309">
                  <rect width="22.0374" height="22.0374" fill="white" transform="translate(0.9375 0.875)" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
        <div className="footerNavbar">
          <Link to="/my-invoice">
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16 8.00007V4.50073C16 3.66899 16 3.25312 15.8248 2.99755C15.6717 2.77425 15.4346 2.62257 15.1678 2.57715C14.8623 2.52517 14.4847 2.69944 13.7295 3.04799L4.85901 7.14206C4.18551 7.45291 3.84875 7.60834 3.60211 7.84939C3.38406 8.06249 3.21762 8.32262 3.1155 8.6099C3 8.93486 3 9.30575 3 10.0475V15.0001M16.5 14.5001H16.51M3 11.2001L3 17.8001C3 18.9202 3 19.4802 3.21799 19.9081C3.40973 20.2844 3.71569 20.5903 4.09202 20.7821C4.51984 21.0001 5.07989 21.0001 6.2 21.0001H17.8C18.9201 21.0001 19.4802 21.0001 19.908 20.7821C20.2843 20.5903 20.5903 20.2844 20.782 19.9081C21 19.4802 21 18.9202 21 17.8001V11.2001C21 10.08 21 9.51992 20.782 9.09209C20.5903 8.71577 20.2843 8.40981 19.908 8.21806C19.4802 8.00007 18.9201 8.00007 17.8 8.00007L6.2 8.00007C5.0799 8.00007 4.51984 8.00007 4.09202 8.21806C3.7157 8.40981 3.40973 8.71577 3.21799 9.09209C3 9.51991 3 10.08 3 11.2001ZM17 14.5001C17 14.7762 16.7761 15.0001 16.5 15.0001C16.2239 15.0001 16 14.7762 16 14.5001C16 14.2239 16.2239 14.0001 16.5 14.0001C16.7761 14.0001 17 14.2239 17 14.5001Z'
                stroke={
                  urlParams === "my-invoice" ? "#FFFFFF" : "#98A2B3"
                }
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <span>Billing</span>
          </Link>
        </div>
        <div className="footerNavbar">
          <Link to="/my-messenger">
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.5 12H7.51M12 12H12.01M16.5 12H16.51M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.1971 3.23374 14.3397 3.65806 15.3845C3.73927 15.5845 3.77988 15.6845 3.798 15.7653C3.81572 15.8443 3.8222 15.9028 3.82221 15.9839C3.82222 16.0667 3.80718 16.1569 3.77711 16.3374L3.18413 19.8952C3.12203 20.2678 3.09098 20.4541 3.14876 20.5888C3.19933 20.7067 3.29328 20.8007 3.41118 20.8512C3.54589 20.909 3.73218 20.878 4.10476 20.8159L7.66265 20.2229C7.84309 20.1928 7.9333 20.1778 8.01613 20.1778C8.09715 20.1778 8.15566 20.1843 8.23472 20.202C8.31554 20.2201 8.41552 20.2607 8.61549 20.3419C9.6603 20.7663 10.8029 21 12 21ZM8 12C8 12.2761 7.77614 12.5 7.5 12.5C7.22386 12.5 7 12.2761 7 12C7 11.7239 7.22386 11.5 7.5 11.5C7.77614 11.5 8 11.7239 8 12ZM12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12ZM17 12C17 12.2761 16.7761 12.5 16.5 12.5C16.2239 12.5 16 12.2761 16 12C16 11.7239 16.2239 11.5 16.5 11.5C16.7761 11.5 17 11.7239 17 12Z'
                stroke={
                  urlParams === "my-messenger" ? "#FFFFFF" : "#98A2B3"
                }
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <span>Messages</span>
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Header;
