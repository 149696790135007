import React from 'react';
import { Modal } from 'react-bootstrap';
import { DESKIE_API as API } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../Pagination/Pagination';
import { formatResourceDate } from '../../CommonFunction/Function';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from "react-router-dom";

interface UpcomingProps {
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    upcomingBookings: any;
    page: any;
    setPage: any;
    limit: any;
    setLimit: any;
    prevButton: any;
    nextButton: any;
    pageValue: any;
    totalValue: any;
    prevPage: any;
    nextPage: any;
};

const UpcomingMobile = ({ show, setShow, handleClose, upcomingBookings, page, setPage, limit, setLimit, prevButton, nextButton, pageValue, totalValue, prevPage, nextPage }: UpcomingProps) => {
    const navigate = useNavigate();
    const viewResource = (resourceId: string) => {
        return navigate(`${resourceId}`);
    };
    return (
        <Modal show={show} onHide={handleClose} centered size="lg" className='mobileFilesModal'>
            <h1 className='filesHeading'><span onClick={handleClose} className="icon"><FontAwesomeIcon icon={faArrowLeft} /></span> Resources</h1>
            <div className="filesContent">
                <div className='mobileFilesHeading mt-2'>
                    <h6>Upcoming Bookings</h6>
                    {/* <button className='viewAll'>Select All</button> */}
                </div>
                {upcomingBookings && upcomingBookings.map((resource: any, i: number) => <div key={`resource` + i} className='bookingPerson'>
                    <img src={`${API}/${resource.resource_image}`} alt='edit' />
                    <div>
                        <p>
                            {resource.resource_name}{" "}
                            <span>({resource.creator_name})</span>{" "}
                        </p>
                        <span>
                            {formatResourceDate(resource.book_date)},{" "}
                            {resource.start_time} - {resource.end_time}
                        </span>
                    </div>
                    <button onClick={() => viewResource(resource.resource_id)}>
                        <FontAwesomeIcon icon={faEye} />
                    </button>
                </div>
                )}
            </div>
            <Pagination page={page} paginationTitle="files" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={upcomingBookings} />
        </Modal>
    )
}

export default UpcomingMobile